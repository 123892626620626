
import { defineComponent } from 'vue';
import initLogin from '@/views/base/login/initLogin';
import '@/assets/less/index.less';

export default defineComponent({
    setup() {
        const {
            getLogin,
            login,
            loginParams,
            disableTime,
            jumpUrl,
            isShowJumpPage
        } = initLogin();

        return {
            getLogin,
            login,
            loginParams,
            disableTime,
            jumpUrl,
            isShowJumpPage
        };
    }
});
