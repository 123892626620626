import { reactive, ref } from 'vue';
import { Login } from '@/api/base/all.type';
import { loginApi } from '@/api';
import noticeBase from '@/util/notice.base';
import router from '@/router';
import { LoginResponse } from '@/views/base/login/all.type';
import {
    superManagerRole,
    installerRole,
    communityRole,
    propertyRole,
    officeRole
} from '@/data/src/account';
import importFaceTipControl from '@/components/view/pm/import-face-tip/importFaceTipUtil';
import CryptoJS from 'crypto-js';
import { useStore } from 'vuex';
import { setStorage } from '@/util/storage';

// 加密token和新框架对齐
// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse('3333e6e143439162');
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse('e3bbe7e3ba84431b');
/**
 * 加密方法
 * @param data
 * @returns {string}
 */
function encrypt(data: object) {
    const dataHex = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}
// 6.5.2免密登录需求：退出清除sessionStorage
if (sessionStorage.getItem('token')) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('account');
} else {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('account');
}
const initLogin = () => {
    const store = useStore();
    // 账号密码
    const loginParams = reactive<Login>({
        Account: '',
        PassWd: ''
    });

    // 禁用时间
    const disableTime = ref(0);

    const isShowJumpPage = ref(false);
    const jumpUrl = ref('');
    function goToAkubola(akuvoxUrl: string) {
        const unityUrl = `${process.env.VUE_APP_MIDDLE_URL}?joinType=login&akuvoxUrl=${akuvoxUrl}`;
        loginApi.getSmartHomeUri((res: {Url: string}) => {
            jumpUrl.value = `${res.Url}&unity_url=${
                encodeURIComponent(unityUrl) as string}`;
            window.location.href = jumpUrl.value;
            // const isOpen = window.open(jumpUrl.value);
            // if (!isOpen) {
            //     isShowJumpPage.value = true;
            // }
        });
    }
    // 判断一个数是不是2的n次幂
    function checkBusiness(num: number) {
        // eslint-disable-next-line no-bitwise
        return (num > 0) && ((num & (num - 1)) === 0);
    }
    // 登录
    const login = () => {
        // 6.5.2 dis免密登录：防止dis切ins后又登录的情况
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('account');

        if (!loginParams.Account) {
            noticeBase.message(WordList.LoginErrorUser, 'error');
            return;
        }
        if (!loginParams.PassWd) {
            noticeBase.message(WordList.LoginErrorPassword, 'error');
            return;
        }
        // 为了解决passwd双向绑定导致页面密码框数据变多的问题
        const params = { ...loginParams };
        params.PassWd = hex_md5(loginParams.PassWd);

        const type = localStorage.getItem('InstallerLoginType');
        loginApi.login(params, [(data: LoginResponse) => {
            const res = data.data;
            localStorage.setItem('token', res.token);
            localStorage.setItem('grade', res.grade);
            localStorage.setItem('account', res.account);
            localStorage.setItem('userName', res.UserName);
            localStorage.setItem('manageTimeZone', res.timeZone);
            localStorage.setItem('communityID', res.communityID);
            localStorage.setItem('peraccount', res.perAccount);
            localStorage.setItem('initialization', res.Initialization);
            if (
                res.grade === installerRole.toString()
                || res.grade === communityRole.toString()
                || res.grade === officeRole.toString()
            ) {
                localStorage.removeItem('Community');
                const insUrl = '/installerIndex/communityhome';
                store.dispatch('getEnableSmartHomeOption', () => {
                    if (store.state.enableSmartHome && res.Business !== undefined) {
                        if (checkBusiness(res.Business)) {
                            if (res.Business === 1) {
                                router.push((type === '0' || type == null) ? '/installerIndex/user' : '/installerIndex/communityhome');
                            } else {
                            // 请求获取家居地址
                                goToAkubola(insUrl);
                            }
                        } else {
                            router.push({
                                path: '/middle-page',
                                query: {
                                    joinType: 'login',
                                    akuvoxUrl: (type === '0' || type == null) ? '/installerIndex/user' : '/installerIndex/communityhome'
                                }
                            });
                        }
                    } else {
                        router.push((type === '0' || type == null) ? '/installerIndex/user' : '/installerIndex/communityhome');
                    }
                });
            } else if (res.grade === propertyRole.toString()) {
                // 登录pm清空人脸导入存储数据
                const { initImportFaceTipData } = importFaceTipControl();
                initImportFaceTipData();
                if (res.IsNew === '0') {
                    // TODO 旧PM为迁移
                    router.push('/propertyIndex');
                } else {
                    router.push('/propertyIndex');
                }
            } else if (res.grade === superManagerRole.toString()) {
                // router.push('/superIndex');
                localStorage.setItem('token', encrypt({ value: res.token }));
                localStorage.setItem('grade', encrypt({ value: res.grade }));
                localStorage.setItem('account', encrypt({ value: res.account }));
                localStorage.setItem('userName', encrypt({ value: res.UserName }));
                localStorage.setItem('manageTimeZone', encrypt({ value: res.timeZone }));
                localStorage.setItem('communityID', encrypt({ value: res.communityID }));
                localStorage.setItem('peraccount', encrypt({ value: res.perAccount }));
                localStorage.setItem('initialization', encrypt({ value: res.Initialization }));
                // formBeta=true 目前先留着
                window.location.href = `${process.env.VUE_APP_NEW_URL}/supermanager/personalDevice`;
            } else {
                localStorage.setItem('haveCustomer', res.HaveCustomer);
                localStorage.setItem('business', res.Business.toString());
                const disUrl = '/distributorIndex';
                store.dispatch('getEnableSmartHomeOption', () => {
                    if (store.state.enableSmartHome && res.Business !== undefined) {
                        if (checkBusiness(res.Business)) {
                            if (res.Business === 1) {
                                router.push(disUrl);
                            } else {
                                goToAkubola(disUrl);
                            }
                        } else {
                            router.push({
                                path: '/middle-page',
                                query: {
                                    joinType: 'login',
                                    akuvoxUrl: disUrl
                                }
                            });
                        }
                    } else {
                        router.push(disUrl);
                    }
                });
            }
        }, false], (error: any) => {
            if (error.code === 4) {
                disableTime.value = parseInt(error.time, 10);
                const timer = setInterval(() => {
                    disableTime.value -= 1;
                    if (disableTime.value <= 0) {
                        clearInterval(timer);
                    }
                }, 1000);
                return;
            }
            if (error.code === 3 && error.number !== -1) {
                noticeBase.messageBox(
                    'alert',
                    WordList.LoginLimitPre + error.number + WordList.LoginLimitNext,
                    WordList.TabelDeleteTitle,
                    'error',
                    WordList.TabelFootSure
                );
                return;
            }
            noticeBase.messageBox(
                'alert',
                error.msg,
                WordList.TabelDeleteTitle,
                'error',
                WordList.TabelFootSure
            );
        });
    };

    // 回车
    const getLogin = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            login();
        }
    };

    return {
        getLogin,
        login,
        loginParams,
        disableTime,
        jumpUrl,
        isShowJumpPage
    };
};
export default initLogin;
